import { createStore } from "vuex";
import Cookies from 'js-cookie';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white", 
    mcolor: "",
    darkMode: false,
    isDashboard: true,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    role: Cookies.get('role') || "Non-Admin"
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    role(state, payload) {
      state.role = payload;
    },
    isDashboard(state, payload) {
      state.isDashboard = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
    commit("sidebarType", payload);
    },
    broadcastRoleChange({ commit }, role) {
      commit("role", role); // Commit the mutation
    }
  },
  getters: {
    isAdmin: (state) => state.role,
  },
});
