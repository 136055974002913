<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    data-scroll="true"
  >
    <div class="px-1 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" 
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center ms-md-auto" 
        >
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Type here..."
            />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center"> 
           <a v-if="!token" id="signUp_key" @click="openSignUp"></a>
           <a v-if="!token" class="cursor-pointer" id="signin_key" @click="openSignIn">
            <i class="custom-icon profile-white-icon me-sm-1"></i> 
              <span class="d-sm-inline d-none px-0 nav-link font-weight-bold text-white align-top	">Sign In</span></a>
              <div v-if="token" class="dropdown">
              <a class="nav-link font-weight-bold text-white dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="d-inline-block handle-large-text">{{userName}}</span>
              </a>

              <ul class="dropdown-menu small-dropdown-menu" aria-labelledby="dropdownMenuLink"> 
                <li><a class="dropdown-item cursor-pointer" href="/Settings"><i class="custom-icon settings-icon me-sm-2 align-text-top"></i><span class="align-top">Settings</span></a></li>
                <li><a class="dropdown-item cursor-pointer" @click="signOut"><i class="custom-icon signout-icon me-sm-2 align-text-top"></i><span class="align-top">Sign out</span> </a></li>
              </ul>
            </div>
          </li> 
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <Signin v-if="isSignIn" @update:emitSignIn="isSignIn = false"></Signin>
  <SignUp v-if="isSignUp" @update:emitSignUp="isSignUp = false"></SignUp>
</template>

<script setup>

import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import Signin from "../../views/Signin.vue";
import SignUp from "../../views/Signup.vue";
import { useMeta } from '../../../src/views/components/composables/useMetaTags';
useMeta('Work Break - Navbar Page', 'Manage your work breaks and tasks efficiently');

import Cookies from 'js-cookie'; 
const store = useStore(); 
const isSignIn = ref(false);
const isSignUp = ref(false);
const route = useRoute(); 
const token = computed(() => {
  return Cookies.get('access_token');
});
const userName = computed(() => {
  return Cookies.get('name');
});
const isMobile = computed(() => window.innerWidth <= 800);
const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});
 
onMounted(()=>{ 
  if(isMobile.value) {
   const sidenav_show = document.querySelector("#app");
   sidenav_show.classList.add("g-sidenav-hidden");
   sidenav_show.classList.remove("g-sidenav-pinned");
   store.commit("isPinned", false);
  }
})
const openSignIn = () => { 
  isSignIn.value = true;
  }

  const openSignUp = () => { 
  console.log("signUp open");
  isSignUp.value = true;
  }
async function signOut() { 
    Cookies.remove('name');
    Cookies.remove('id'); 
    Cookies.remove('access_token'); 
     Cookies.remove('refresh_token'); 
    Cookies.remove('email'); 
    Cookies.remove('role'); 
    location.reload(true);
}

const minimizeSidebar = () => store.commit("sidebarMinimize");
</script>

<style scoped>
.small-dropdown-menu {
  right: 0;
      left: -25px !important;
}
.dropdown-toggle::after {
    margin-left: .345em;
    vertical-align: .55em;
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
}
#signUp_key {
  visibility: hidden;
}
</style>
