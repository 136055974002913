<template>
  <div class="m-2 p-2">
    <h3>Contact WorkBreak</h3>
    <p><strong>Effective Date:</strong> October 29, 2024</p>
    <p class="card p-3">
      At WorkBreak, we value our users' feedback and are always here to assist you with any inquiries you may have. Feel free to get in touch with us through the following methods:
    </p>
    <h4>Email Support</h4>
    <p>If you have any questions, concerns, or need help with any feature, please don't hesitate to contact us via email:</p>
    <ul>
      <li><strong>Email</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="mailto:info@work-break.com">info@work-break.com</a></li>
    </ul>
    <h4>Customer Support</h4>
    <p>For customer support, we offer the following channels:</p>
    <ul>
      <li><strong>Phone</strong>: Call us at <strong>(+129) 447-31067</strong></li>
      <li><strong>Live Chat</strong>: Reach us through our live chat feature on the website.</li>
    </ul>
    <h4>Business Inquiries</h4>
    <p>For partnership opportunities, collaborations, or any business-related inquiries, please reach us at:</p>
    <ul>
      <li><strong>Email</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="mailto:info@work-break.com">info@work-break.com</a></li>
    </ul>
    <h4>Follow Us</h4>
    <p>Stay updated with the latest news and updates from WorkBreak:</p>
    <ul>
        <li><strong>Linkedin</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://www.linkedin.com/in/worksbreaks/">linkedin.com/in/worksbreaks</a></li>
      <li><strong>Facebook</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://www.facebook.com/worksbreaks">facebook.com/worksbreaks</a></li>
      <li><strong>Twitter</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://twitter.com/worksbreaks">twitter.com/worksbreaks</a></li>
      <li><strong>Instagram</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://instagram.com/worksbreaks">instagram.com/worksbreaks</a></li>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useMeta } from '../composables/useMetaTags';

useMeta(
  'Contact Us - WorkBreak',
  'Get in touch with WorkBreak. Find customer support, business inquiry contacts, and more ways to connect with us.'
);

const store = useStore();
store.state.isAbsolute = false;
store.commit("isDashboard", false);
</script>

<style scoped>
a {
  display: contents;
}
</style>
