
<template>
    <div ref="modalRef" class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog border shadow p-3 mb-5 bg-light rounded">
        <div class="modal-content">
        <div class="modal-header color-black">
        <h5 class="modal-title">
            Task Details
        </h5>
       <h5 class="card-title">Scheduled: {{ convertDate(taskDetails.scheduled) }}</h5>
       </div>
        <div class="modal-body"> 
                <div class="row justify-content-center">
                <div class="col-12"> 
                            <div class="my-2">
                              <span class="badge bg-primary">{{ taskDetails.priority }}</span>
                              <span class="ms-2 badge bg-warning">{{ taskDetails.status }}</span>
                        </div>
                        <h4 class="card-title">{{ taskDetails.title }}</h4>
                        <p class="card-text">{{ taskDetails.description }}</p> 
                    </div>
                    </div> 
            
        </div>
        <div class="modal-footer bg-gradient">
            <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="closeDialog()">
                close
            </button>    
        </div>
        </div>
    </div>
    </div>
</template>
<script setup>
 import { ref, onMounted } from 'vue';
 import { convertDate } from '../../utilities/transforms';
import { Modal } from 'bootstrap';
import { useMeta } from '../../../views/components/composables/useMetaTags';
useMeta('Task Details - WorkBreak', 'View task details, including priority, status, and description. Easily manage and track tasks with WorkBreak using work-break.');

const modalRef = ref(null);
let modal = null;

const { taskDetails } = defineProps({
  taskDetails: { type: Object, required: true }
});

const closeDialog = () => {
  modal.hide();
};

const showDialog = () => {
  if (modal) {
    modal.show();
  }
};

// watch(() => props.taskDetails, () => {
//   if (props.taskDetails && modal) {
//     showDialog();
//   }
// }, { immediate: true });

onMounted(() => {
  modal = new Modal(modalRef.value, {
    backdrop: 'static',
    keyboard: false
  });
  showDialog();
});

// onBeforeUnmount(() => {
//   if (modal) {
//     modal.hide();
//     modal.dispose();
//   }
// });
</script>