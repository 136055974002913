<template>
  <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header color-black">
          <h5 class="modal-title"> 
            Viewing <strong>{{ details?.name }}</strong> Breaks
          </h5>
          <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- Correctly call the Breaks component -->
          <Breaks v-if="details" :viewingId="details.userId"></Breaks>
        </div>
        <!-- <div class="modal-footer bg-gradient">
          <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="updateRecord">
            Save
          </button>    
        </div> -->
      </div>
    </div>
  </div>
</template>



<script setup>
import { shallowRef, onMounted } from 'vue';
import { useMeta } from '../composables/useMetaTags';
import Breaks from '../archives/Breaks.vue'; // Correctly import the component
import { Modal } from "bootstrap"; 

useMeta(
  'Administrators Dashboard | Break Management',
  'WorkBreak empowers administrators to view and manage user breaks effectively. Monitor break schedules, review details, and ensure optimal productivity with streamlined management tools.'
);

const { details } = defineProps({
  details: { type: Object, required: true }
});

const modalRef = shallowRef(null);
let modal = null;

onMounted(() => {
  modal = new Modal(modalRef.value);
  console.log("show edit modal", modal);
  modal.show(); // Ensure proper modal initialization
});

const emit = defineEmits(["update:closeBreaks"]);
function closeDialog() {
  emit('update:closeBreaks', false);
  modal.hide();
}
</script>

 
