<template>
  <main>
    <div class="d-flex justify-content-center align-items-center vh-100" v-if="isLoading">
      <loading></loading>
    </div>
    <div v-else>
    <div class="container-fluid">
      <div
        class="page-header min-height-200">
        <!-- <span class="mask bg-gradient-success opacity-6"></span> -->
      </div>
      
      <div v-if="info && info.name" class="card shadow-lg mt-n8">
        <div class="card-body p-3">
          <div class="row gx-4"> 
              <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-uppercase">{{ info?.name }}</h5>
                <p class="mb-0 font-weight-bold text-sm">Take a look at your work hours and break times</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="row mt-5">
        <div v-if="info && filteredBreaks?.filtered" :class="info && info.email ? 'col-lg-6 col-md-6 col-sm-12 col-xs-12' : 'col-12'">
          <div class="card"> 
            <div class="card-body">
          
              <div class="mt-4">

                <div class="pb-0 card-header bg-primary border-radius-xs border-radius-md border-radius-lg">
                  <div class="d-flex justify-content-between">
                  <h6 class="pb-1">Breaks </h6>
                   <div class="d-flex justify-content-between align-items-center pb-2 rounded bg-primary text-white">
 
                    <span class="font-weight-bold">Total: {{ filteredBreaks.totalCount }}</span>
                  </div></div></div>
                    <div class="card-body">
                      <div class="row">
                         <div v-for="(value, key) in filteredBreaks.filtered" :key="key" class="mb-2">
                          <div class="bg-secondary text-white d-flex justify-content-between align-items-center p-2 rounded">
                            <span>{{ key }}</span>
                            <span>{{ value }}</span>
                          </div>
                        </div>
                        </div> 
                    </div> 

                   <div class="pb-0 card-header bg-primary border-radius-xs border-radius-md border-radius-lg">
                  <div class="d-flex justify-content-between">
                  <h6 class="pb-1">Tasks </h6>
                   <div class="d-flex justify-content-between align-items-center pb-2 rounded bg-primary text-white">
                    <span class="font-weight-bold">Total: {{ filteredTasks.totalCount }}</span>
                  </div></div></div>
                    <div class="card-body">
                    <div class="row"> 
                  <div v-if="orderedStatuses.length" class="col-lg-6 col-md-6 col-sm-12 col-md-12"> 
                    <h2 class="fw-bold">Statuses</h2>
                    <div class="col-md-5 mt-3 d-block"> 
                      <div 
                        v-for="status in orderedStatuses" 
                        :key="status.key" 
                        :class="`p-2 mb-2 ${getTaskBadgeClass(status.key)}`">
                        {{ status.key }}: {{ status.count }}
                      </div>
                    </div>
                  </div>

                  <div v-if="orderedPriorities.length" class="col-lg-6 col-md-6 col-sm-12 col-md-12"> 
                    <h2 class="fw-bold">Priorities</h2>
                    <div class="col-md-5 mt-3 d-block">
                      <div 
                        v-for="priority in orderedPriorities" 
                        :key="priority.key" 
                        :class="`p-2 mb-2 ${getTaskBadgeClass(priority.key)}`">
                        {{ priority.key }}: {{ priority.count }}
                      </div>
                    </div>
                  </div> 
                    </div>
                    </div> 
                  </div>
                </div> 
          </div>
        </div>
        <div v-if="info && info.email" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pe-3">
          <profile-card :userId="userId" :name="info.name" :email="info.email" />
          <admin :userId="userId" :name="info.name" :email="info.email" />
        </div>
        <div>
          <div class="col-12 w-50 float-end mt-4"><button type="button"  @click="deleteAccount" class="btn btn-danger col-12 text-white">Delete Account</button></div>
          </div>
      </div>
      </div>
  </main>
</template>
<script setup>

import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ProfileCard from "./components/profile/ProfileCard.vue";
import admin from "./components/profile/admin.vue";
import apiClient from '../api/apiClient';
import loading from "./components/common/loading.vue";
import Cookies from 'js-cookie'; 
import { useMeta } from '../views/components/composables/useMetaTags';

useMeta('Profile Settings - Update Your Email & Password | WorkBreak', 
       'Manage your profile settings on WorkBreak. Update your email address and password easily to keep your account secure. Track your work hours, breaks, and task statuses all in one place.');

const userId = Cookies.get('id');
const isLoading = ref(false);
// const body = document.getElementsByTagName("body")[0];
const info = ref(null);
const store = useStore();
// let breakCounts = ref(0);
// let taskCounts = ref(0);

const predefinedStatusOrder = [
  'To Do', 
  'In Progress', 
  'In Review', 
  'On Hold', 
  'Deferred', 
  'Invalid', 
  'Blocked', 
  'Completed'
];

const predefinedPriorityOrder = [
  'Critical', 
  'Urgent', 
  'High', 
  'Important', 
  'Normal', 
  'Low', 
  'Blocker'
];
const token = computed(() => {
      return Cookies.get('access_token');;
  });
const getInfo = async () => {
    try {   isLoading.value = true;
            const response = await apiClient.get('/settings/info', {
                params: { userId }
            });
            info.value = response.data;
            console.log('User Info:', response.data);    
          } catch (error) {
            console.error('getInfo:', error);
          } finally {
            isLoading.value = false;
          }
    }

    const filteredTasks = computed(() => {
      const { totalCount, ...filtered } = info.value.taskCounts;
      return { totalCount, filtered };
    });

    const orderedStatuses = computed(() => {
      const statuses = filteredTasks.value.filtered?.status || {};
      return predefinedStatusOrder
        .filter((status) => statuses[status] !== undefined)
        .map((status) => ({ key: status, count: statuses[status] }));
    });

    const orderedPriorities = computed(() => {
      const priorities = filteredTasks.value.filtered?.priority || {};
      return predefinedPriorityOrder
        .filter((priority) => priorities[priority] !== undefined)
        .map((priority) => ({ key: priority, count: priorities[priority] }));
    });


     const filteredBreaks = computed(() => {
       const { totalCount, ...filtered } = info.value.breakTypeCounts; 
      return {totalCount, filtered};
    });

    const getTaskBadgeClass = (key) => { 
    const classes = {
      // Priority-related classes
      "Normal": "bg-secondary text-white",  // Gray
      "High": "bg-warning text-dark",      // Yellow
      "Critical": "bg-danger text-white",  // Red
      "Urgent": "bg-danger text-white",    // Red
      "Important": "bg-info text-white",   // Blue
      "Low": "bg-light text-dark",         // Light gray
      "Blocker": "bg-dark text-white",     // Black

      // Status-related classes
      "In Progress": "bg-primary text-white",   // Blue
      "To Do": "bg-dark text-white",            // Dark gray/Black
      "Completed": "bg-success text-white",     // Green
      "Deferred": "bg-light text-dark",         // Light gray
      "Invalid": "bg-secondary text-white",     // Gray
      "On Hold": "bg-warning text-dark",        // Yellow
      "In Review": "bg-info text-white",        // Light blue
      "Blocked": "bg-dark text-white",          // Black
    };

    // Default class if key not matched
    return classes[key] || "bg-light text-dark";
  }

onMounted( async() => {
  if(!token.value) {
    window.location.href = '/';
  }
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
  await getInfo();
});

const deleteAccount = async () => {
   Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to delete your account?<br>
                  Your account will be deleted in 
                  <strong>14 days</strong>.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, mark for deletion',
          }).then(async (result) => {
        if (result.isConfirmed) {
          try { 
           await apiClient.post(`/settings/delete-account/${userId}`);
          } catch (error) {
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting your account. Please try again.',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          }
        }
      });
};

</script>

<style scoped>
  .page-header {
    background-image: '';
    margin-right: -24px;
    margin-left: -34%;
  }
  .card-header:first-child {
    border-radius: 1rem;
  }

  /* ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
} */

</style>