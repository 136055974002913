export const getDurations = (breaks) => {
    const breaksArray = Object.values(breaks);
    if(!breaksArray) return [];
    return breaksArray.reduce((acc, item) => {
          const durationInSeconds = item.duration;
          if (!acc[item.type]) {
              acc[item.type] = 0;
          }
          acc[item.type] += durationInSeconds;
          return acc;
      }, {});
};
// convert "2024-06-02T34:40:3.000Z" to 2024-06-02 3:40PM 
export const formatDateToDatetimeLocal = (dateString) => {
        if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

export const convertDate = (isoString) => {
  const date = new Date(isoString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}; 
// convert "2024-06-02T00:00:00.000Z" to 2024-06-02 like to mm/dd/yyyy
// export const formatDateToMMDDYYYY = (dateString) => {
//     const date = new Date(dateString);
//     const pad = (num) => num.toString().padStart(2, '0');
//     const month = pad(date.getMonth() + 1); // Months are 0-based
//     const day = pad(date.getDate());
//     const year = date.getFullYear();
//     return `${year}-${month}-${day}`;
// };

// convert Minutes To Hours And Minutes
export const convertMinutesToHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours > 0 && minutes> 0) {
    return `${hours} Hour(s) and ${minutes} Minute(s)`;
  } else if (hours > 0 && minutes < 1) {
    return `${hours} Hour(s)`;
  } else if (hours < 1 && minutes > 0) {
    return `${minutes} Minute(s)`;
  }
  else {
    return `0 Minute`;
  }
}

export const Hours24TimeFormatter = (date) => {
    console.log("AMPMTimeFormatter",date)
    if(!date) return;
    return new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

export const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);

    // Determine AM or PM suffix
    const suffix = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours from 24-hour format to 12-hour format
    const formattedHours = ((hours + 11) % 12 + 1);
    
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${suffix}`;
}

export const getTimeDifferenceInMinutes = (startTime, endTime)=> {
    // Convert the times to 24-hour format
    const convertTo24Hour = (time) => {
        const [timeStr, modifier] = time.split(' ');
        let [hours, minutes] = timeStr.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    };

    // Parse the times
    const start = new Date(`1970-01-01T${convertTo24Hour(startTime)}:00`);
    const end = new Date(`1970-01-01T${convertTo24Hour(endTime)}:00`);

    // Calculate the difference in milliseconds
    const differenceInMillis = end - start;

    // Convert the difference to minutes
    const differenceInMinutes = differenceInMillis / 1000 / 60;

    return differenceInMinutes;
}

export const trimLabels = (labels, maxLength = 15) => {
  return labels.map(label => 
    label.length > maxLength ? `${label.substring(0, maxLength)}...` : label
  );
}

export const getDaysBetweenDates = (fromDate, toDate) => {
  const fromTime = new Date(fromDate).getTime();
  const toTime = new Date(toDate).getTime();
  const timeDifference = Math.abs(toTime - fromTime);
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}