<template>
  <div v-if="showAlert" class="position-fixed bottom-0 start-0 w-100 p-2 bg-light shadow-sm z-index-9999 d-flex align-items-center">
    <button @click="dismissAlert" type="button" class="border-0 bg-transparent" aria-label="Close">
      <i class="custom-icon close-icon icon-lg"></i>
    </button>
    <div class="flex-grow-1">
      <h4 class="text-dark text-sm font-bold ms-2 p-1 mb-2">
        Use Work Break app
      </h4>
    </div>
    <a class="btn btn-sm btn-primary ms-2" @click="installApp">
      Continue
    </a>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const showAlert = ref(false);
let deferredPrompt; // Store the deferred prompt

const isMobileDevice = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

// Check if the app is installed
const isInstalled = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  ('standalone' in navigator && navigator.standalone);

// Dismiss the alert and store the dismissal in localStorage
const dismissAlert = () => {
  showAlert.value = false;
  localStorage.setItem('installWorkBreakDismissed', Date.now()); // Store the timestamp of dismissal
};

// Install the app
const installApp = () => {
  if (deferredPrompt) {
    // Show the install prompt to the user
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null; // Reset the prompt
    });
  } else {
    showAlert.value = false;
  }
};

onMounted(() => {
  const dismissedTimestamp = localStorage.getItem('installWorkBreakDismissed');

  // Check if the alert was dismissed within the last 24 hours
  if (dismissedTimestamp && Date.now() - dismissedTimestamp < 24 * 60 * 60 * 1000) {
    showAlert.value = false; // Don't show the alert if dismissed within the last 24 hours
    return;
  }

  // Show alert if on a mobile device and the app is not installed
  if (isMobileDevice() && !isInstalled()) {
    showAlert.value = true;
  }

  // Listen for the 'beforeinstallprompt' event
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault(); // Prevent the default install prompt from showing
    deferredPrompt = event; // Save the event so we can trigger it later
  });

  // Listen for the 'appinstalled' event
  window.addEventListener('appinstalled', () => {
    console.log('App installed successfully');
    showAlert.value = false; // Hide the alert after installation
  });
});
</script>

<style scoped>
.z-index-9999 {
  z-index: 9999;
}
</style>
