import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tasks from "../views/Tasks.vue";
import Archives from "../views/Archives.vue"; 
import Settings from "../views/Settings.vue";
import privacyPolicy from "../views/components/privacy-policy/privacyPolicy.vue";
import deleteAccount from "../views/components/delete-account/deleteAccount.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue"; 
import about from "../views/components/about/about.vue";
import contact from "../views/components/contact/contact.vue";
import administrators from "../views/components/administrators/administrators.vue";
import Swal from 'sweetalert2';
// import contactUs from "../views/components/common/conatct-us.vue";
// import axios from 'axios';
import Cookies from 'js-cookie';
import leaves from "../views/components/leaves/leaves.vue";

const handleSocialAuthCallback = async (to, from, next) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);

    const accessToken = urlParams.get('access_token');
    const refreshToken = urlParams.get('refresh_token');
    const name = urlParams.get('name');
    const id = urlParams.get('id');

    if (accessToken && refreshToken) {
      Cookies.set('access_token', accessToken, { expires: 7 });
      Cookies.set('refresh_token', refreshToken, { expires: 7 });
      Cookies.set('name', name, { expires: 7 });
      Cookies.set('id', id, { expires: 7 }); 
      window.history.replaceState({}, document.title, window.location.pathname); 
      location.reload();  
    } else {
      console.error('Missing tokens or user information');
      next('/login'); // Redirect to login if tokens are missing
    }
  } catch (error) {
    Swal.fire('Error', 'Error handling login callback.', 'error');
    next('/login'); // Redirect to login on error
  }
}; 

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/breaks/",
    meta: {
      title: "Breaks | Work Break",
      description: "Welcome to WorkBreak - Track and manage your breaks, tasks, leaves, and improve productivity with workbreak.",
    },
  },
  {
    path: "/auth/callback",
    component: Dashboard, // Assuming Dashboard is the correct component
    beforeEnter: handleSocialAuthCallback,
  },
  {
    path: "/breaks/",
    name: "Breaks",
    component: Dashboard,
    meta: {
      title: "Breaks | WorkBreak",
      description: "Log and track breaks like lunch, coffee, and more. Gain insights into your productivity patterns.",
    },
  },
  {
    path: "/verify-new-email",
    component: () => import("../views/components/common/verify-new-email.vue"),
    beforeEnter: (to, from, next) => {
      if (to.path === from.path) {
        next(false); // Prevent recursion
      } else {
        next();
      }
    },
    meta: {
      title: "Verify New Email | WorkBreak",
      description: "Securely verify your new email address to continue using WorkBreak seamlessly.",
    },
  },
  {
    path: "/verify-reset-password",
    component: () => import("../views/components/common/verify-reset-password.vue"),
    beforeEnter: (to, from, next) => {
      if (to.path === from.path) {
        next(false); // Prevent recursion
      } else {
        next();
      }
    },
    meta: {
      title: "Reset Password | WorkBreak",
      description: "Easily reset your password and regain access to your WorkBreak account.",
    },
  },
  {
    path: "/accept-email-invitation",
    component: () => import("../views/components/common/accept-email-invitation.vue"),
    beforeEnter: (to, from, next) => {
      if (to.path === from.path) {
        next(false); // Prevent recursion
      } else {
        next();
      }
    },
    meta: {
      title: "Accept admin requect | WorkBreak",
     description: "Approve and process email update requests securely to ensure seamless account management on WorkBreak."
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
    meta: {
      title: "Tasks | WorkBreak",
      description: "Manage your tasks efficiently. Set priorities, track progress, and boost productivity.",
    },
  },
  {
    path: "/archives",
    name: "Archives",
    component: Archives,
    meta: {
      title: "Archives | WorkBreak",
      description: "Access your task and break history. Search and review past activities with ease.",
    },
  },
  {
    path: "/leaves",
    name: "Leaves",
    component: leaves,
    meta: {
      title: "Leaves | WorkBreak",
      description: "Plan and manage your leaves effortlessly. Track reasons and stay organized.",
    },
  },
  {
    path: "/administrators",
    name: "Administrator Dashboard",
    component: administrators,
    meta: {
        title: "Administrators | WorkBreak",
        description: "View and manage user breaks, tasks, and leaves efficiently on Work-Break.",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      title: "Settings | WorkBreak",
      description: "Customize your preferences and manage your account settings on Work-Break.",
    },
  },
  {
  path: "/about",
  name: "About",
  component: about,
  meta: {
    title: "About Us | WorkBreak",
    description: "Learn more about WorkBreak, our mission, and how we help you stay productive."
  },
},
{
  path: "/contact",
  name: "Contact",
  component: contact,
  meta: {
    title: "Contact Us | WorkBreak",
    description: "Contact WorkBreak for support, inquiries, or business-related questions."
  },
},
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: privacyPolicy,
    meta: {
      title: "Privacy Policy | WorkBreak",
      description: "Learn how Work-Break protects your data and respects your privacy.",
    },
  },
  {
    path: "/delete-account",
    name: "Delete Account",
    component: deleteAccount,
    meta: {
      title: "Delete Account | Work-Break",
      description: "Understand the process and implications of deleting your Work-Break account.",
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: {
      title: "Sign In | WorkBreak",
      description: "Sign in to WorkBreak to manage your breaks, tasks, and productivity effectively.",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      title: "Sign Up | WorkBreak",
      description: "Create your WorkBreak account and start tracking your breaks, tasks, and productivity.",
    },
  },
  // Catch-all route for unknown URLs
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});



export default router;
