<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <h2 class="text-center mb-4 fs-4">Admin Dashboard</h2>

        <!-- Add User Card -->
        <div class="card mb-3">
          <h5 class="card-header">Add User</h5>
          <div class="text-muted mx-3 mt-2 fs-6">
            <strong>Please add a valid email to invite a user.</strong> Once the user accepts your invitation, you, as an admin, will be able to view their <strong>breaks, tasks, and leaves</strong> and monitor their activity.
          </div>
          <div class="p-3">
           <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
          <!-- Input field for email -->
          <div class="w-100 mb-sm-0 me-sm-3 position-relative">
            <input
              type="text"
              class="form-control"
              placeholder="User Email"
              v-model="userEmail"
              :class="{ 'is-invalid': emailError }"
            />
            <!-- Error message -->
            <div v-if="emailError" class="invalid-feedback position-absolute top-100 left-0">
              {{ emailError }}
            </div>
          </div>

          <!-- Add button -->
          <button
            class="btn btn-primary text-white mt-3 w-100 w-sm-auto"
            :disabled="isLoading"
            :style="{ pointerEvents: isLoading ? 'none' : 'auto' }"
            @click="addUser"
          >
            {{ isLoading ? "Loading..." : "Add" }}
          </button>
        </div>

          </div>
        </div>

        <!-- Users Table -->
          <div v-if="users.length>0" class="table-responsive">
            <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-sm font-weight-bold">User</th>
                <th class="text-uppercase text-secondary text-sm font-weight-bold">Breaks | Tasks | Leaves</th>
                <th class="text-uppercase text-secondary text-sm text-end font-weight-bold">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in users"
                :key="user.id"
                :class="{ 'table-secondary': !user.isAccepted }"
              >
                <!-- User Details -->
                <td>
                  <div class="d-flex align-items-start">
                    <div
                      class="user-icon bg-primary text-white d-flex align-items-center justify-content-center rounded-circle me-3">
                      {{ user.name[0].toUpperCase() }}
                    </div>
                    <div>
                      <strong>{{ user.name }}</strong>
                      <br />
                      <span class="text-muted">{{ user.email }}</span>
                      <br />
                      <span v-if="!user.isAccepted" class="text-danger">(Not Accepted)</span>
                    </div>
                  </div>
                </td>
                <!-- Breaks, Tasks, and Leaves -->
                <td>
                  <a
                    class="cursor-pointer badge bg-warning text-white me-2"
                    @click.prevent="showTagDetails('breaks', user)"
                    :class="{ 'disabled': !user.isAccepted }"
                    :aria-disabled="!user.isAccepted"
                  >
                    View Breaks
                  </a>
                  <a
                    class="cursor-pointer badge bg-success text-white me-2"
                    @click.prevent="showTagDetails('tasks', user)"
                    :class="{ 'disabled': !user.isAccepted }"
                    :aria-disabled="!user.isAccepted"
                  >
                    View Tasks
                  </a>
                  <a
                    class="cursor-pointer badge bg-danger text-white"
                    @click.prevent="showTagDetails('leaves', user)"
                    :class="{ 'disabled': !user.isAccepted }"
                    :aria-disabled="!user.isAccepted"
                  >
                    View Leaves
                  </a>
                </td>
                <!-- Actions -->
                <td class="text-end">
                  <button
                    class="btn btn-danger text-white"
                    @click="deleteUser(user.email)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <div v-else class="card p-3"> 
            <div class="alert alert-warning d-flex align-items-center" role="alert">
              <span>No users have been added.</span>
            </div>
          </div>
        </div>
      </div>
  </div>
  <view-breaks v-if="showBreaks" :details="details" @update:closeBreaks="closeBreak" @close="closeBreak"></view-breaks>
  <view-tasks v-if="showTasks" :details="details" @update:closeTasks="closeTask" @close="closeTask"></view-tasks>
  <view-leaves v-if="showLeaves" :details="details" @update:closeTasks="closeLeave" @close="closeLeave"></view-leaves>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../../../api/apiClient';
import { useMeta } from '../composables/useMetaTags';
import viewBreaks from './view-breaks.vue';
import viewTasks from './view-tasks.vue';
import viewLeaves from './view-leaves.vue';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

useMeta(
  'Administrators Dashboard | Work-Break',
  'Discover how WorkBreak empowers administrators to efficiently add users and manage their breaks, tasks, and leaves, enhancing productivity and streamlined workflow management.'
);
const users = ref([]);
const details = ref(null);
const store = useStore();
store.state.isAbsolute = false;
store.commit("isDashboard", false);
const showBreaks = ref(false);
const showTasks = ref(false);
const showLeaves = ref(false);
const isLoading = ref(false);
const userEmail = ref('');
const emailError = ref('');
const userId = Cookies.get('id');

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!userEmail.value) {
    emailError.value = 'Email is required.';
  } else if (!emailPattern.test(userEmail.value)) {
    emailError.value = 'Please enter a valid email address.';
  } else {
    emailError.value = '';
  }
};

const addUser = async () => {
  validateEmail();
    if (userEmail.value) {
    // Check if the email already exists in the users list
    const emailExists = users.value.some(user => user.email === userEmail.value);

    if (emailExists) {
      emailError.value = 'This email is already added in your list.';
      return;
    }
    try {
      isLoading.value = true;
      const email = Cookies.get('email');
      const name = Cookies.get('name');
      const response = await apiClient.post(`/admin/add-user`, {
        adminId: userId,
        name,
        email,
        userEmail: userEmail.value
      });
      if (response?.data && response?.status === 201){
        userEmail.value = "";
        await getUsers();
        await Swal.fire({
        title: 'User Added!',
        text: `An invitation has been sent to ${userEmail.value}. Once accepted, you will be able to view their breaks, tasks, and leaves.`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
      });
      }
    } catch (error) {
      console.error(error.response.data?.message);
      const message = 'Error: '+ error.response.data?.message || "Error: Please try again.";
      emailError.value = message;
      await Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
    }
    finally { isLoading.value = false;}
  }
};

const deleteUser = (email) => {
  Swal.fire({
    title: 'Are you sure?',
    html: "Do you really want to delete this user?<br> <span class='text-danger font-weight-bolder'>NOTE:</span> This action is irreversible and will permanently remove the user from your list.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete user!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await apiClient.delete(`/admin/delete/${email}`);
        console.log("Success deleted", response);
        if (response.data && response.status === 200) {
          const index = users.value.findIndex(user => user.email === email);
          if (index !== -1) {
            users.value.splice(index, 1);
          }
        }
        Swal.fire('Deleted!', 'The user has been successfully removed.', 'success');
      } catch (error) {
        console.error('Error deleting user:', error);
        Swal.fire('Error!', 'There was an error deleting the user.', 'error');
      }
    }
  });
};

const closeBreak = async () => {
  showBreaks.value = false;
}

const closeTask = async () => {
  showTasks.value = false;
}

const closeLeave = async () => {
  showLeaves.value = false;
}
const showTagDetails = async (type, user) => {
  details.value = user;
  if(type == 'breaks') {
    showBreaks.value = true;
  } else if(type == 'tasks') {
    showTasks.value = true;
  } else if(type == 'leaves') {
    showLeaves.value = true;
  }
};

const getUsers = async () => {
  const response = await apiClient.get(`/admin/get`, {
    params: {
      adminId: userId
    }
  });
  console.log("get user: " + response);
  if (response?.data && response?.status === 200){
      users.value = response.data;
  }
}
onMounted( async() => {
  const isAdmin = Cookies.get('role') === "Admin" ? true : false;
  if(!isAdmin) {
    window.location.href = '/';
  }
  await getUsers();

});
</script>

<style scoped>
.user-icon {
  width: 40px;
  height: 40px;
  font-size: 1rem;
}
a.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
