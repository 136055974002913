<template>
  <div>
    <div
      v-if="isPopupVisible"
      class="position-absolute w-100 top-64 start-0 bg-white border rounded shadow mt-2 p-3 z-index-3"
    ><div
        class="tooltip-arrow position-absolute"
      ></div>
      <div class="text-end">
        <button type="button" class="border-0 bg-transparent" aria-label="Close" @click="togglePopup"><i class="custom-icon close-icon icon-lg"></i></button>
      </div>

      <div>
        <div class="row g-3 align-items-center">
          <div class="col-12 col-md d-flex mb-1 flex-column align-items-start gap-1">
            <label for="startDate" class="form-label mb-0">
              <strong>From</strong>
            </label>
            <input
              id="startDate"
              type="date"
              class="form-control"
              v-model="start"
              :max="end"
              @change="errorMessage = ''"
            />
          </div>

          <div class="col-12 col-md d-flex mb-1 flex-column align-items-start gap-1">
            <label for="endDate" class="form-label mb-0">
              <strong>To</strong>
            </label>
            <input
              id="endDate"
              type="date"
              class="form-control"
              v-model="end"
              :min="start"
              @change="errorMessage = ''"
            />
          </div>

          <div
            class="col-12 col-md-auto mt-5 d-flex justify-content-center align-items-center"
          >
            <button
              class="btn btn-primary w-100 w-md-auto"
              @click="applyRange"
              :disabled="!!errorMessage"
            >
              Apply
            </button>
          </div>
        </div>

        <p v-if="errorMessage" class="text-danger text-center fs-6 fw-bold mt-4">
          {{ errorMessage }}
        </p>

        <div class="mt-4">
          <ul class="list-unstyled row g-2 row-cols-1 row-cols-md-3">
            <li
              v-for="(range, label) in customRanges"
              :key="label"
              class="col d-flex"
            >
              <button
                class="btn w-100"
                :class="selectedRange === label
                  ? 'bg-primary text-white'
                  : 'btn-outline-secondary'"
                @click="setCustomRange(range)"
              >
                {{ label }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, nextTick } from "vue";

const dateFormat = (date) => date.toISOString().split("T")[0];

const start = ref("");
const end = ref("");
const errorMessage = ref("");
const selectedRange = ref("");
const isPopupVisible = ref(false);

const emit = defineEmits(["update:date-range"]);

const customRanges = computed(() => ({
  "Last 7 Days": [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()],
  "Last 30 Days": [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), new Date()],
  "Last 6 Months": [
    new Date(new Date().setMonth(new Date().getMonth() - 6)),
    new Date(),
  ],
  "Last 12 Months": [
    new Date(new Date().setMonth(new Date().getMonth() - 12)),
    new Date(),
  ],
  "Last 2 Years": [
    new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
    new Date(),
  ],
  "Last 5 Years": [
    new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
    new Date(),
  ],
}));

const togglePopup = () => {
  isPopupVisible.value = !isPopupVisible.value;
};

const validateDateRange = () => {
  const startDate = start.value ? new Date(start.value) : null;
  const endDate = end.value ? new Date(end.value) : null;

  if (!startDate && !endDate) {
    errorMessage.value = "Select at least one date.";
    selectedRange.value = "";
    return false;
  }
  if (startDate && endDate && endDate < startDate) {
    errorMessage.value = "End date cannot be earlier than start date.";
    selectedRange.value = "";
    return false;
  }
  errorMessage.value = "";
  return true;
};

const applyRange = () => {
  if (validateDateRange()) {
    emit("update:date-range", { start: start.value, end: end.value });
    togglePopup(); // Close the popup after applying
  }
};

const setCustomRange = (range) => {
  const [startRange, endRange] = range;
  start.value = dateFormat(startRange);
  end.value = dateFormat(endRange);
  nextTick(() => {
    emit("update:date-range", { start: start.value, end: end.value });
  });
  togglePopup(); // Close the popup after selecting a custom range
};

onMounted(() => {
  togglePopup();
});
</script>

<style scoped>
.tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #0d6efd;
  top: -8px;
  right: 5%;
}
</style>
