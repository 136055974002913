<template>
  <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header color-black">
          <h5 class="modal-title"> 
            Viewing <strong>{{ details?.name }}</strong> Tasks
          </h5>
          <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="inProgressTasks.length>0" class="card"> 
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-2"> Inprogress Tasks </h6> 
            </div>
          </div>
   
            <div class="row mt-3">
              <!-- Loop through tasks -->
              <div class="col-md-4 mb-4" v-for="(task, index) in inProgressTasks" :key="index">
                <div class="card h-100 d-flex flex-column">
                  <div class="card-body flex-grow-1">
                    <h5 class="card-title fw-bold truncate-two-lines">{{ task.title }}</h5>
                    <p class="card-text truncate-four-lines">{{ task.description }}</p>
                    <div>
                      <span class="badge bg-primary">{{ task.priority }}</span>
                      <span class="ms-2 badge bg-warning">{{ task.status }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>

          <div v-if="inProgressTasks.length === 0" class="card p-3"> 
            <div class="alert alert-warning d-flex align-items-center" role="alert">
              <span>There is no Inprogress Task.</span>
            </div>
          </div>
          <Tasks v-if="details" :viewingId="details.userId"></Tasks>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import { shallowRef, ref, onMounted } from 'vue';
import { useMeta } from '../composables/useMetaTags';
import Tasks from '../archives/Tasks.vue'; 
import apiClient from '../../../api/apiClient';
import { Modal } from "bootstrap"; 

useMeta(
  'Administrators Dashboard | Break Management',
  'WorkBreak empowers administrators to view and manage user tasks effectively. Monitor task schedules, review details, and ensure optimal productivity with streamlined management tools.'
);

const { details } = defineProps({
  details: { type: Object, required: true }
});
let inProgressTasks = ref([]);
const modalRef = shallowRef(null);
let modal = null;

const loadInProgressRecords = async () => {
    inProgressTasks.value = [];
     try {
      const tasks = await apiClient.get('task/getInProgressTasks', {
        params: {
          userId: details.userId,
          isScheduled: true
        }
      });
      inProgressTasks.value = tasks.data;
    } catch (error) {
        console.error('Error tasks:', error);
      }
  }
onMounted( async() => {
  modal = new Modal(modalRef.value);
  modal.show(); 
  await loadInProgressRecords();
});

const emit = defineEmits(["update:closeTasks"]);
function closeDialog() {
  emit('update:closeTasks', false);
  modal.hide();
}
</script>
<style scoped>

@media (min-width: 1200px) {
  .modal.show .modal-dialog {
      max-width: 90% !important;
  }
}
</style>
 
