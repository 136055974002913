 <template>
  <div class="card z-index-2 p-3">
     <div class="pb-0 border-radius-xs border-radius-md border-radius-lg">
          <div class="d-flex justify-content-between">
            <h6 class="pb-1">All TASKS</h6>
            <span class="">Total Tasks : {{ recordCount }}</span>
          </div>
        </div>
         <taskInfo2 v-if="token && recordCount?.length===0 && !viewingId"></taskInfo2>
         <div v-if="recordCount === 0 && viewingId" class="alert alert-warning d-flex align-items-center" role="alert">
          <span>This user has not added any <strong>Task</strong> yet.</span>
        </div>
        <taskInfo3 v-if="!token"></taskInfo3>
        <div v-if="recordCount?.length===0 && !viewingId" class="row">
          <a @click="addTaskManually" class="col-12 text-end text-success p-4 start-link">Add Task</a>
        </div>
        
        <div v-if="tasks?.length === 0" class="card p-3"> 
            <div class="alert alert-warning d-flex align-items-center" role="alert">
              <span>No Task Found.</span>
            </div>
          </div>

          <div v-if="isMobile" class="d-flex align-items-center mb-3 d-md-none">
            <span>{{ showSearchBlock ? "Show Advance Search" : "Hide Search" }}</span>
            <i
              class="custom-icon ms-2 cursor-pointer"
              :class="showSearchBlock ? 'arrowDown-circle' : 'arrowUp-circle'"
              @click="showSearchBlock = !showSearchBlock"
            ></i>
          </div>

        <div v-if="recordCount > 0 && ((!showSearchBlock && isMobile) || !isMobile)" class="row d-flex flex-wrap align-items-center"> 
          <div class="mb-3 col-md-3 col-sm-12 position-relative">
            <label for="title" class="form-label">Title</label>
            <input type="text" class="form-control pe-5" v-model="title">
            <i
              class="custom-icon close-icon icon-lg text-muted cursor-pointer position-absolute translate-middle-y top-50 mt-3 end-6"
              v-if="title"
              @click="clearField('title')"
            ></i>
          </div> 
          <div class="mb-3 col-md-3 col-sm-12 position-relative search-date">
            <label for="currentDate" class="form-label">Scheduled Date</label>
            <input
                type="text"
                id="currentDate"
                placeholder="Search By Schedule On Date"
                class="form-control bg-white"
                v-model="scheduleDate"
                readonly
                @click="showScheduleDatePicker = !showScheduleDatePicker"
              />
                <span class="position-absolute top-75 end-0 translate-middle-y pe-4">
                  <i
                    class="custom-icon calender-icon cursor-pointer"
                    @click="showScheduleDatePicker = !showScheduleDatePicker"
                  ></i>
                  <!-- Clear Icon -->
                  <i
                    class="custom-icon close-icon icon-sm text-muted cursor-pointer position-absolute top-33 end-3 translate-middle-y me-5"
                    v-if="scheduleDate"
                    @click="clearField('scheduleDate')"
                  ></i>
                </span>

              <date-picker-range
                v-if="showScheduleDatePicker"
                v-model:start="start"
                v-model:end="end"
                @update:date-range="updateScheduledDate"
                @close="showScheduleDatePicker = false"
              ></date-picker-range>
          </div>
          
          <div class="mb-3 col-md-3 col-sm-12 position-relative search-date">
            <label for="currentDate" class="form-label">Create Date</label>
            <!-- <input type="date" class="form-control" v-model="createdDate"> -->
            <input
                type="text"
                id="currentDate"
                placeholder="Search By Created Date"
                class="form-control bg-white"
                v-model="createdDate"
                readonly
                @click="showCreatedDatePicker = !showCreatedDatePicker"
              />
                <span class="position-absolute top-75 end-0 translate-middle-y pe-4">
                  <i
                    class="custom-icon calender-icon cursor-pointer"
                    @click="showCreatedDatePicker = !showCreatedDatePicker"
                  ></i>
                  <!-- Clear Icon -->
                  <i
                    class="custom-icon close-icon icon-sm text-muted cursor-pointer position-absolute top-33 end-3 translate-middle-y me-5"
                    v-if="createdDate"
                    @click="clearField('createdDate')"
                  ></i>
                </span>

              <date-picker-range
                v-if="showCreatedDatePicker"
                v-model:start="start"
                v-model:end="end"
                @update:date-range="updateCreatedDate"
                @close="showCreatedDatePicker = false"
              ></date-picker-range>
          </div>
          
          <div class="mb-2 col-md-2 col-sm-12 search-select position-relative">
            <label for="type" class="form-label">Status</label>
            <select class="form-select form-control-alternative mb-2" v-model="status">
              <option value="" disabled selected>...Select</option>
              <option value="To Do">To Do</option>
              <option value="In Progress">In Progress</option>
              <option value="In Review">In Review</option>
              <option value="Completed">Completed</option>
              <option value="Invalid">Invalid</option>
              <option value="On Hold">On Hold</option>
              <option value="Deferred">Deferred</option>
              <option value="Blocked">Blocked</option>
            </select> 
            <i
              class="custom-icon close-icon icon-lg text-muted cursor-pointer position-absolute translate-middle-y top-50 end-10 me-4"
              v-if="status"
              @click="clearField('status')"
            ></i>
          </div>
          
          <div class="mb-2 col-md-2 col-sm-12 search-select position-relative">
            <label for="type" class="form-label">Priority</label>
            <select class="form-select handle-focus form-control-alternative mb-2" v-model="priority">
              <option value="" disabled selected>...Select</option>
              <option value="Normal">Normal</option>
              <option value="Important">Important</option>
              <option value="Critical">Critical</option>
              <option value="Low">Low</option>
              <option value="High">High</option>
              <option value="Urgent">Urgent</option>
              <option value="Blocker">Blocker</option>
            </select> 
            <i
              class="custom-icon close-icon icon-lg text-muted cursor-pointer position-absolute translate-middle-y top-50 end-10 me-4"
              v-if="priority"
              @click="clearField('priority')"
            ></i>
          </div>
          
          <div class="mb-2 col-md-1 col-sm-12 pt-3 mt-4">
            <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="getTasks(1)">
              Search
            </button>    
          </div>
        </div>

 
        <loading v-if="isLoading"></loading>
        <div class="card" v-if="!isLoading">
 
      <div v-if="tasks?.length>0" class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Description
               </th>
               <th
                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Scheduled
              </th>
              <th
                class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Priority
              </th>
               <th
                class="text-uppercase text-end text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
           
            <tr v-for="(value, index) in tasks" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto"> 
                    <h6 class="mb-0 text-sm handle-large-text" :title="value.title">{{value.title}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-muted mb-0 handle-large-text" :title="value.description">{{ value.description }}</p>
              </td>
              <td>
                <p class="text-muted mb-0 handle-large-text" :title="value.scheduled">{{ convertDate(value.scheduled) }}</p>
              </td>
              <td class="text-center">
                <span class="text-muted align-middle">{{ value.status }}</span>
              </td> 
              <td class="text-center">
                <span class="text-muted align-middle">{{ value.priority }}</span>
              </td> 
              <td class="text-end">
                <span class="me-2 mouse-pointer" @click="viewTaskDetails(value)"><i class="custom-icon view-icon"></i></span>
                <span v-if="!viewingId" class="ms-2 mouse-pointer" @click="editTask(value)"><i class="custom-icon edit-icon"></i></span>
                <span v-if="!viewingId" class="me-3 mouse-pointer" @click="deleteTask(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
 </div>
 <div class="row">
      <div class="d-inline-block col-10">
        <Pagination v-if="recordCount>10" :records-per-page="recordsPerPage" :record-count="recordCount" @changePage="onChangePage"
          :current-page="pageNumber"></Pagination>
      </div>
      </div>
  </div>
  <ViewTask v-if="showViewModal" :task-details="selectedTask" @close="showViewModal = false"></ViewTask>
  <AddTask v-if="showEdit" :edit-record="selectedTask" is-archives="true"  @update:archivesEditTask="getTasks(1)"></AddTask>
 </template>

<script setup>
import {ref, onMounted, computed, nextTick} from 'vue';
import AddTask from '../../components/tasks/AddTask';
import Pagination from './pagination';
import apiClient from '../../../api/apiClient';
  import datePickerRange from '../common/date-picker-range.vue';
import { convertDate } from "../../utilities/transforms";
import { deleteRecord } from '../composables/deleteRecords';
import { isAuth } from '../composables/userAuth';
import ViewTask from '../../components/tasks/ViewTask';
import loading from '../common/loading.vue';
import taskInfo2 from "../common/task-info2.vue";
import taskInfo3 from "../common/task-info3.vue";
import { useMeta } from '../composables/useMetaTags';

useMeta(
  'Task List',
  'View and manage your tasks. You can filter tasks by title, scheduled date, status, and priority, or add new tasks to the list.'
);

import Cookies from 'js-cookie';
let showEdit = ref(false);
let showViewModal = ref(false);
const selectedTask = ref(null);
const showCreatedDatePicker = ref(false);
const showScheduleDatePicker = ref(false);
const showSearchBlock = ref(true);

const props = defineProps({
    viewingId: { type: String, required: false }
  });
  // const userId = computed(() => props.id || Cookies.get('id'));

  let userId = Cookies.get('id');
  if (props.viewingId){
    userId = props.viewingId;
  }
 
const recordsPerPage = ref(10);
  let pageNumber = ref(1);
  let tasks = ref([]);
  let recordCount = ref(0);
  let status = ref('');
  let priority = ref('');
  let title = ref('');
  let scheduleDate = ref('');
  let createdDate = ref('');
  let isLoading = ref(false); 
  const emit = defineEmits(['taskCounts']);

    // Create date search
    const createdDateFrom = ref("");
    const createdDateTo = ref(""); 
    const updateCreatedDate = async ({ start, end }) => {
      showCreatedDatePicker.value = false;
      createdDateFrom.value = start || null;
      createdDateTo.value = end || null;
      if (start && end) {
        createdDate.value = `${convertDate(start)} - ${convertDate(end)}`;
      } else if (start) {
        createdDate.value = `After ${convertDate(start)}`;
      } else if (end) {
        createdDate.value = `Before ${convertDate(end)}`;
      } else {
        createdDate.value = "";
      } 
    };

    // sche date search
    const startScheduleDate = ref("");
    const endScheduleDate = ref(""); 
    const updateScheduledDate = async ({ start, end }) => {
      showScheduleDatePicker.value = false;
      startScheduleDate.value = start || null;
      endScheduleDate.value = end || null;
      if (start && end) {
        scheduleDate.value = `${convertDate(start)} - ${convertDate(end)}`;
      } else if (start) {
        scheduleDate.value = `After ${convertDate(start)}`;
      } else if (end) {
        scheduleDate.value = `Before ${convertDate(end)}`;
      } else {
        scheduleDate.value = "";
      } 
    };

 const token = computed(() => {
      return Cookies.get('access_token');;
  });
      const getTasks = async (page) => { 
          try { 
              isLoading.value = true;
              pageNumber.value = page;
              tasks.value = [];
              const limit = recordsPerPage.value;
              const skip = (pageNumber.value - 1) * recordsPerPage.value;
              let params = {
                userId,
                limit,
                skip
              }  
              if (status.value) {
                params.status = status.value;
              }
              if (priority.value) {
                params.priority = priority.value;
              }
              if (title.value) {
                params.title = title.value;
              }
              if (createdDateFrom.value) {
                params.createdDateFrom = createdDateFrom.value;
              }
              if (createdDateTo.value) {
                params.createdDateTo = createdDateTo.value;
              }
              if (startScheduleDate.value) {
                params.startScheduleDate= startScheduleDate.value;
              }
              if (endScheduleDate.value) {
                params.endScheduleDate = endScheduleDate.value;
              }
              const response = await apiClient.get('/task/getArchivesTasks', {
              params 
            });
            if(response?.data){
            tasks.value = response.data.tasks;
            recordCount.value = response.data.totalCount; 
            if(recordCount.value === 0){
               emit('taskCounts');
            }
            console.error('tasks.value:', tasks.value);
            }
            } catch (error) {
              console.error('Error responseForToday:', error);
            }
              finally { 
              isLoading.value = false;
            }
          } 

    const viewTaskDetails = async (task) => {
    try{
      showViewModal.value = false;
      await nextTick(); 
      selectedTask.value = task;
      showViewModal.value = true;
      }catch(error){
      console.error('Error break/get:', error);
      }
    };
   
    const deleteTask = async (id) => {
    try{
      const repsonseId = await deleteRecord(id, "task");
    console.log("delete response", repsonseId);
    if(repsonseId) { 
      await getTasks(1);
    }
    }catch(error){
      console.error('Error break/get:', error);
      }
  }

  const editTask = async (task) => {
  try{ 
     showEdit.value = false;
    await nextTick(); 
    selectedTask.value = {...task};
    showEdit.value = true;
    }catch(error){
      console.error('Error break/get:', error);
      }
  };

  const addTaskManually = async() => {
  try{
      if(isAuth()) { 
        showEdit.value = false;
        await nextTick(); 
        selectedTask.value = null;
        showEdit.value = true;
      }
      }catch(error){
      console.error('Error break/get:', error);
      }
    }

    const onChangePage = async (newPage) => {
    try{
      tasks.value = [];
      isLoading.value = true;
      window.scrollTo(0, 0);
      await getTasks(newPage);
      }catch(error){
      console.error('Error break/get:', error);
      }
    };
 
    const isMobile = computed(() => window.innerWidth <= 800);

    const clearField = (type) => {
      const fields = {
          title: () => (title.value = ""),
          status: () => (status.value = ""),
          priority: () => (priority.value = ""),
          scheduleDate: () => { (scheduleDate.value = ""), startScheduleDate.value = null, endScheduleDate.value = null },
          createdDate: () => { (createdDate.value = ""), createdDateFrom.value = null, createdDateTo.value = null }
      };

      if (fields[type]) {
          fields[type](); // Call the corresponding function to clear the field
          getTasks(1); // Fetch tasks
      } else {
          console.warn(`Invalid field type: ${type}`);
      }
    };  
    onMounted( async() => { 
      await getTasks(1);
    });

</script> 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
.top-75 {
    top: 75%;
  }
  .top-33 {
    top: 33%;
  }
  .search-date, .search-select {
      width: 100% !important;
  } 
  .search-select i {
      margin-top: .7rem;
  }
  @media (min-width: 1200px) {
  .search-date {
      width: 21% !important;
  }
  .search-select {
      width: 12% !important;
  }
}
</style>