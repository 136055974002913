  <template>
  <div class="card z-index-3 p-3">
      <div class="pb-0 border-radius-xs border-radius-md border-radius-lg">
          <div class="d-flex justify-content-between">
            <h6 class="pb-1">BREAKS</h6>
            <span class="">Total Breaks : {{ recordCount }}</span>
          </div>
            
        </div>
        <breakInfo2 v-if="token && recordCount===0 && !viewingId"></breakInfo2>
        
        <div v-if="recordCount === 0 && viewingId" class="alert alert-warning d-flex align-items-center" role="alert">
          <span>This user has not added any <strong>Break</strong> yet.</span>
        </div>

        <breakInfo3 v-if="!token"></breakInfo3>
        <div v-if="recordCount===0 && !viewingId" class="row">
          <a @click="addBreakManually" class="col-12 text-end text-success p-4 start-link">Add Break</a>
        </div>
          
              
        <div v-if="recordCount > 0" class="row">
        <div class="pt-4 mt-2 col-auto d-none d-md-block">
            <strong>Search:</strong>
          </div>
        <div class="mb-3 col-md-4 col-sm-12 position-relative">
          <label for="currentDate" class="form-label d-none d-md-block">Created Date</label>
          <input
            type="text"
            id="currentDate"
            placeholder="Search By Created Date"
            class="form-control bg-white"
            v-model="formattedDate"
            readonly
            @click="showDatePicker = !showDatePicker"
          />
            <span class="position-absolute top-64 end-0 translate-middle-y pe-4">
              <i
                class="custom-icon calender-icon cursor-pointer"
                @click="showDatePicker = !showDatePicker"
              ></i>
              <!-- Clear Icon -->
              <i
                class="custom-icon close-icon icon-sm text-muted cursor-pointer position-absolute top-33 end-3 translate-middle-y me-5"
                v-if="formattedDate"
                @click="clearDate"
              ></i>
            </span>

          <date-picker-range
            v-if="showDatePicker"
            v-model:start="start"
            v-model:end="end"
            @update:date-range="updateFormattedDate"
            @close="showDatePicker = false"
          ></date-picker-range>
        </div>

        <!-- Break Type Field -->
        <div class="mb-3 col-md-4 col-sm-12">
          <label for="type" class="form-label d-none d-md-block">Break Type</label>
          <div class="position-relative">
          <select v-model="type" class="form-select handle-focus form-control-alternative mb-3 cursor-pointer" @change="handleBreakTypeChange">
          <option value="" disabled selected>--- Select Break Type ---</option>
              <option v-for="brk in breakTypes" :key="brk" :value="brk">{{ brk }}</option>
            </select>
            <i
              class="custom-icon close-icon icon-lg text-muted cursor-pointer position-absolute top-50 end-4 translate-middle-y me-3"
              v-if="type"
              @click="clearType"
            ></i>
          </div>
        </div>
      </div>

        <loading v-if="isLoading"></loading>
        <div v-if="token && breaks?.length===0 && !viewingId" class="alert alert-warning d-flex align-items-center" role="alert">
          <span>No Record Found</span>
        </div>
        <div class="card" v-if="!isLoading">
      <div v-if="token" class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0" v-if="breaks.length>0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
              >
                Break Type
              </th>
              <th
                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Reasons
              </th>
              <th
                class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Created
              </th>
              <th
                class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >
                Duration (minutes)
              </th>
              
                <th
                class="text-uppercase text-end text-secondary text-sm font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in breaks" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm handle-large-text" :title="value.type">{{value.type}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-muted mb-0 handle-large-text" :title="value.reason">{{ value.reason }}</p>
              </td>
                <td class="text-center">
                <span class="text-muted align-middle">{{ convertDate(value.createdDate) }}</span>
              </td> 
              <td class="text-center">
                <span class="text-muted align-middle">{{ value.duration }}</span>
              </td> 
                
              <td class="text-end">
                <span class="me-2 mouse-pointer" @click="viewBreak(value)"><i class="custom-icon view-icon"></i></span>
                <span v-if="!viewingId" class="me-1 mouse-pointer" @click="editBreak(value)"><i class="custom-icon edit-icon"></i></span>
                <span v-if="!viewingId" class="ms-2 mouse-pointer" @click="deleteBreak(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table> 
      </div>
      </div>
      <div class="row">
      <div class="d-inline-block col-12">
        <Pagination v-if="recordCount>10" :records-per-page="recordsPerPage" :record-count="recordCount" @changePage="onChangePage"
          :current-page="pageNumber"></Pagination>
      </div> 
      </div>
  </div> 
    
  <EditBreak v-if="showEdit" :edit-record="editRecord" is-archives="true" @update:archivesEditBreaks="getBreaks(1)"></EditBreak>
  <ViewBreak v-if="showViewModal" :break-details="selectedBreak" @close="showViewModal = false"></ViewBreak>
  </template>

  <script setup>
  import { onMounted, ref, shallowRef, computed, nextTick} from 'vue'; 
  import apiClient from '../../../api/apiClient';
  import { convertDate } from "../../utilities/transforms";
  import { deleteRecord } from '../composables/deleteRecords';
  import { isAuth } from '../composables/userAuth';
  import Pagination from './pagination';
  import ViewBreak from '../breaks/ViewBreak.vue';
  import EditBreak from '../breaks/EditBreak.vue';
  import breakInfo2 from "../common/break-info2.vue";
  import breakInfo3 from "../common/break-info3.vue";
  import Cookies from 'js-cookie';  
  import loading from '../common/loading.vue';
  import datePickerRange from '../common/date-picker-range.vue';
  import { useMeta } from '../composables/useMetaTags';

  useMeta(
  'Breaks Management',
  'View and manage your break records. Add, edit, delete, and search for breaks based on type, date, and duration.'
  );

  const props = defineProps({
    viewingId: { type: String, required: false }
  });
  // const userId = computed(() => props.id || Cookies.get('id'));

  let userId = Cookies.get('id');
  if (props.viewingId){
    userId = props.viewingId;
  }
  const recordsPerPage = ref(10);
  const token = computed(() => {
      return Cookies.get('access_token');;
  });
  let showViewModal = ref(false);
  const selectedBreak = ref(null);
  let showEdit = ref(false);
  let editRecord = ref(null);

  let pageNumber = ref(1);
  let breaks = ref([]);
  let breakTypes = ref([]);
  let recordCount = ref(0);
  let createdDateFrom = shallowRef(null);
  let createdDateTo = shallowRef(null);
  let type = ref('');
  let isLoading = ref(false);

  // date picker portion

  const start = ref("");
    const end = ref("");
    const formattedDate = ref("");
    const showDatePicker = ref(false);

    const updateFormattedDate = async ({ start, end }) => {
      showDatePicker.value = false;
      createdDateFrom.value = start || null;
      createdDateTo.value = end || null;
      if (start && end) {
        formattedDate.value = `${convertDate(start)} - ${convertDate(end)}`;
      } else if (start) {
        formattedDate.value = `After ${convertDate(start)}`;
      } else if (end) {
        formattedDate.value = `Before ${convertDate(end)}`;
      } else {
        formattedDate.value = "";
      }
      await getBreaks(1);
    };

    // const formatDate = (date) => {
    //   const options = { day: "2-digit", month: "short", year: "numeric" };
    //   return new Date(date).toLocaleDateString("en-US", options);
    // };

    // end date picker
  const deleteBreak = async (id) => {
    const repsonseId = await deleteRecord(id, "break");
    if(repsonseId) { 
      await getBreaks(1);
    }
  }

  const viewBreak = async (task) => { 
      showViewModal.value = false;
    await nextTick(); 
    selectedBreak.value = task;
    showViewModal.value = true;
  };

  const editBreak = async (value) => {
    // shallow copy of the value
    showEdit.value = false;
    await nextTick(); 
    editRecord.value = {...value}
    showEdit.value = true;
  }

  const clearDate = async () => {
     createdDateFrom.value = null;
     createdDateTo.value = null;
     formattedDate.value = null;
     await getBreaks(1);
  }

  const handleBreakTypeChange = async() => {
    await getBreaks(1);
  };

  const clearType = async () => {
     type.value = '';
     await getBreaks(1);
  }

  const getBreaks = async (page) => {
  console.log('getBreaks page: ' + type.value);
    try { 
        isLoading.value = true;
        pageNumber.value = page;
        breaks.value = [];
        const limit = recordsPerPage.value;
        const skip = (pageNumber.value - 1) * recordsPerPage.value;
        let params = {
          userId,
          limit,
          skip
        }  
        if (type.value) {
          params.type = type.value;
        }
        params["createdDateFrom"] = createdDateFrom.value;
        params["createdDateTo"] = createdDateTo.value;
        const response = await apiClient.get('/break/getArchivesBreaks', {
        params 
      });
      if(response?.data){
      breaks.value = response.data.breaks;
      recordCount.value = response.data.totalCount;
      }
      } catch (error) {
        console.error('Error responseForToday:', error);
      }
        finally { 
        isLoading.value = false;
      }
    } 

    const onChangePage = async (newPage) => {
      breaks.value = [];
      isLoading.value = true;
      window.scrollTo(0, 0);
      await getBreaks(newPage);
    };

    const getBreakTypes = async () => {
    try {  
            const response = await apiClient.get('/type/get', {
            params: {
              userId: userId
            }
          });
          breakTypes.value = response.data[0].type; 
          console.log('type/get:', response.data[0].type);     
          } catch (error) {
            console.error('Error break/get:', error);
          } 
    }

    const addBreakManually = async() => {
      if(isAuth()) { 
        showEdit.value = false;
        await nextTick(); 
        editRecord.value = null;
        showEdit.value = true;
      }
    }
    
    onMounted( async() => {
      await getBreakTypes()
      await getBreaks(1);
    }); 

  </script>

  <style scoped>
  .start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
  }
  .top-64 {
    top: 64%;
  }
  .top-33 {
    top: 33%;
  }
  select option[value=""] {
    color: gray;
  }
  </style>
