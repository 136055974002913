<template>
  <div class="card custom-card mb-3"> 
    <div><h5 class="float-start">Add Break</h5>
      <p v-if="timerRunning" class="float-end"><span class="d-none d-md-inline-block">Break Duration:</span>{{ elapsedTimeDisplay }}</p></div>
    
    <div class="row mt-3 mb-3">
    <div class="col-12 col-md-3 mb-3 mb-md-0">
        <button class="btn bg-gradient-success btn-sm me-2 mb-3 w-100" @click="startTimer" v-if="!timerRunning">START BREAK</button>
        <button class="btn btn-danger text-white btn-sm me-2 mb-3 w-100" @click="stopTimer" v-if="timerRunning">BACK TO WORK</button>
            <select v-model="selectedBreakType" class="form-select form-control-alternative mb-3" required @change="handleBreakTypeChange">
      <option v-for="brk in breakTypes" :key="brk" :value="brk">{{ brk }}</option>
    </select>

    </div>
    <div class="col-12 col-md-9 mt-2">
        <textarea class="form-control form-control-alternative" maxlength="2000" v-model="textareaContent" rows="3" placeholder="Enter your text here"></textarea>
    </div>
  </div> 
  <div v-if="timerRunning" class="float-end text-end mt-3">
      <p class="d-inline-block mb-2 fw-bold">Changed my mind, no break needed.</p>       
      <button class="btn btn-warning btn-sm text-white ms-2 mb-2 text-md d-inline-block" @click="resetTimer">CANCEL</button>
  </div> 
  </div> 
</template>

<script setup>
import { ref, onMounted, watch, computed, shallowRef } from 'vue';
import Swal from 'sweetalert2';
// import { useStore } from "vuex";
import apiClient from '../../../api/apiClient';
import Cookies from 'js-cookie';  
const emit = defineEmits(["update:emitTodayBreaks"]);
import { isAuth } from '../composables/userAuth';
import { Hours24TimeFormatter } from '../../utilities/transforms';
import { useMeta } from '../composables/useMetaTags'; 

useMeta(
  'Add Break',
  'Start or cancel a break. Track your break duration and enter the reason for taking a break.'
);

// const store = useStore(); 
// Dropdown and textarea variables
const selectedBreakType = ref('');
const textareaContent = ref('');
const breakId = shallowRef('');
const props = defineProps({
  breakTypes: { type: Array, required: true, default: () => []  }
});

const breakTypes = ref(props.breakTypes); 

const handleBreakTypeChange = async() => {
  // if the break already in runing state, only update the break type
  if(breakId.value){
    await addBreak(false, true);
  }
};

watch(
  () => props.breakTypes,
  (newBreakTypes) => {  
    if(newBreakTypes){
      breakTypes.value = [...newBreakTypes]; 
      if(breakTypes.value.length > 0 && !breakId.value)
       selectedBreakType.value = breakTypes.value[0];
    }
  },
  { immediate: true } 
);

const timerRunning = ref(false);
let timerInterval;
let startTime = "";
const elapsedTime = ref(0);
let initialTime = ref(new Date().toISOString());
const elapsedTimeDisplay = ref("00:00:00");
const userId = Cookies.get('id');


const formattedTime = computed(() => {
  const seconds = Math.floor(elapsedTime.value / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = minutes?Math.floor(minutes / 60):0;

  const duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
  // store.commit("elapsedTime", duration);
  return duration;
});
 
// Watch for changes in elapsedTime to update elapsedTimeDisplay
// watch(elapsedTime, () => {
// try{
//   store.commit("elapsedTime", formattedTime.value);
//   elapsedTimeDisplay.value = store.state.elapsedTime;
//   }catch(error){
//       console.error('Error break/get:', error);
//       }
// });


// Function to start the timer
const startTimer =  async() => {
 
  if(isAuth()) {
    if (!selectedBreakType.value) {
      alert('Please select an option from the dropdown.');
      return;
    }
    
    if (!timerRunning.value) { 
      initialTime = ref(new Date().toISOString());
      startTime = Date.now() - elapsedTime.value;
      timerInterval = setInterval(updateTimer, 1000);
      timerRunning.value = true;
       await addBreak(false, false);
    }
  }
 
};

const parseDuration = (duration) => {
try{
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    return hours * 60 + minutes + (seconds>0?1:0);
    }catch(error){
      console.error('Error break/get:', error);
      }
};

const addBreak = async (isClosed, isAdd) => {
  console.log('initial time value',initialTime.value)
   const stoppedTime = new Date();
      stoppedTime.setMinutes(stoppedTime.getMinutes() + 1);
      const dateOnly = new Date();
      dateOnly.setUTCHours(0, 0, 0, 0);
    const data = {
      type: selectedBreakType.value,
      reason: textareaContent.value ?textareaContent.value: '',
      duration: parseDuration(formattedTime.value),
      userId:userId,
      // startTime: Hours24TimeFormatter(initialTime.value),
      endTime:  Hours24TimeFormatter(stoppedTime),
      createdDate: dateOnly,
      isClosed // mean start/update or closed
    };
    console.error('xxxxxxxxxxyyyyyyyyyyyyyyyyyzzzzzzzz:', data); 
    try {
      if(!isAdd) {
        console.log('starta break', initialTime.value);
        console.log('starta a break time', Hours24TimeFormatter(initialTime.value));
        // to start a break
          data.startTime = Hours24TimeFormatter(initialTime.value);
            console.log('starta timeeeeee', data.startTime);
          const response = await apiClient.post('/break/add', data);
          console.log('Response:', response);
          if(response.status && response.data){
            breakId.value= response.data;
          }
      } else {
        // to stop a break, only update the value
        // data.startTime = initialTime.value;
        const response = await apiClient.put(`/break/edit/${breakId.value}`, data);
        return response.data;
      }
       } catch (error) {
        console.error('Error:', error);
      }
}
// Function to stop the timer
const stopTimer = async () => {
  // elapsedTimeDisplay.value !=='00:00:00' mean user wait atleast 1 second
  if (timerRunning.value && elapsedTimeDisplay.value !=='00:00:00') {
    clearInterval(timerInterval);
    timerRunning.value = false;
    elapsedTimeDisplay.value =  formattedTime.value; 
    let differenceInMilliseconds = new Date() - new Date(startTime);
      let differenceInMinutes = differenceInMilliseconds / (1000 * 60);
      if (differenceInMinutes < 1) {
          Swal.fire({
              icon: 'info',
              title: 'Notice',
              text: 'Less than a minute will be considered as 1 minute',
              confirmButtonText: 'OK'
          });
      }
      const response = await addBreak(true, true);
      if(response){
        breakId.value = '';
        textareaContent.value = "";
        emit('update:emitTodayBreaks');
        clearTimer();
      }
};
};

const clearTimer = async () => {
  clearInterval(timerInterval);
  timerRunning.value = false;
  elapsedTime.value = 0;
  textareaContent.value = "";
  elapsedTimeDisplay.value = "00:00:00";
  initialTime.value = new Date().toISOString();
  breakId.value = '';
}

// Function to reset the timer
const resetTimer = async() => {
try{
  if(breakId.value){
  Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to <strong>CANCEL</strong> it.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, mark for Canceled',
            cancelButtonText: 'Close'
          }).then(async (result) => {
        if (result.isConfirmed) {
          try { 
           const repsonseId = await apiClient.delete(`break/delete/${breakId.value}`, "break");
          console.log("delete response", repsonseId);
          if(repsonseId) { 
            clearTimer();
          }
          } catch (error) {
            console.log("error in canceling", error);
          }
        }
      });    
  }
  
  }catch(error){
      console.error('Error break/get:', error);
      }
};

// Function to update the timer display
const updateTimer = () => {
try{
  const now = Date.now();
  elapsedTime.value = now - startTime;
  elapsedTimeDisplay.value = formattedTime.value;
  }catch(error){
      console.error('Error break/get:', error);
      }
};

    const fetchBreakState = async () => {
      try {
        const isClosed = false; 
        const response = await apiClient.get(`/break/get-break/${isClosed}`, {
          params: { userId },
        });
        console.log("fetch running break", response);        
        if (response.data !== "" && !response.data.isClosed) {
          breakId.value = response.data._id;
          initialTime.value = response.data.startTime;
          textareaContent.value = response.data.reason;
          selectedBreakType.value = response.data.type;
          timerRunning.value = true; 
          const today = new Date();
          const [startHour, startMinute] = initialTime.value.split(":").map(Number);
          startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startHour, startMinute).getTime();

          timerInterval = setInterval(updateTimer, 1000);
          // elapsedTimeDisplay.value = convertMinutesToHMS(response.data.duration);
        }
      } catch (error) {
        console.error("Error fetching break state:", error);
      }
    };
  const token = computed(() => {
      return Cookies.get('access_token');;
  });
  onMounted( async () => {
  //try{
    if(token.value) {
      await fetchBreakState();
    }
    // if(breakTypes.value)
    //   selectedBreakType.value = breakTypes.value[0];
    //   }catch(error){
    //   console.error('Error break/get:', error);
    //   }
  });

</script>

<style scoped>
.container {
  max-width: 900px;
  margin: auto;
}
textarea {
  resize: none;
  border: 1px solid #d2d6da !important;
}
.custom-card {
    padding: 1rem;
  }

  /* Desktop view (screens wider than 768px) */
  @media (min-width: 768px) {
    .custom-card {
      padding: 3rem;
    }
  }
</style>
