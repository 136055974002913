<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
  
   <ul class="navbar-nav">
  <li class="nav-item">
    <sidenav-item
      to="/breaks/"
      :class="getRoute() === 'breaks' ? 'active' : ''"
      navText="Dashboard"
    >
      <template v-slot:icon>
        <i class="custom-icon home-icon"></i>
      </template>
    </sidenav-item>
  </li>

  <li class="nav-item">
    <sidenav-item
      to="/tasks"
      :class="getRoute() === 'tasks' ? 'active' : ''"
      navText="Tasks"
    >
      <template v-slot:icon>
        <i class="custom-icon task-icon"></i>
      </template>
    </sidenav-item>
  </li>

  <li class="nav-item">
    <sidenav-item
      to="/archives"
      :class="getRoute() === 'Archives' ? 'active' : ''"
      navText="Archives"
    >
      <template v-slot:icon>
        <i class="custom-icon archives-icon"></i>
      </template>
    </sidenav-item>
  </li>

  <li class="nav-item">
    <sidenav-item
      to="/leaves"
      :class="getRoute() === 'Leaves' ? 'active' : ''"
      navText="Leaves"
    >
      <template v-slot:icon>
        <i class="custom-icon ban-icon"></i>
      </template>
    </sidenav-item>
  </li>

  <!-- Admin Menu Item -->
  <li
    class="nav-item"
    :class="{ disabled: !isAdmin }"
    :aria-disabled="!isAdmin"
  >
    <sidenav-item
      to="/administrators"
      :class="[getRoute() === 'administrators' ? 'active' : '', !isAdmin ? 'unclickable' : '']"
      navText="Admin"
      :disabled="!isAdmin"
      :tabindex="!isAdmin ? -1 : 0"
    >
      <template v-slot:icon>
        <i class="custom-icon admin-icon"></i>
      </template>
    </sidenav-item>

    <!-- Show message if user is not an admin -->
    <span
      v-if="!isAdmin && token"
      class="text-muted ms-4"
      style="font-size: 12px; display: block;"
    >
      Please switch to Admin Role to access this section
    </span>
  </li>

  <li class="nav-item cursor-pointer">
    <a @click="openContactUs" class="nav-link">
      <div class="text-center d-flex align-items-center justify-content-center">
        <i class="custom-icon envelope-icon"></i>
      </div>
      <span class="nav-link-text ms-2">Support</span>
    </a>
  </li>

  <li class="mt-3 nav-item">
    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2">
      MANAGEMENT PAGES
    </h6>
  </li>

  <li v-if="token" class="nav-item">
    <sidenav-item
      to="/settings"
      class="profile"
      navText="Profile"
    >
      <template v-slot:icon>
        <i class="custom-icon profile-icon"></i>
      </template>
    </sidenav-item>
  </li>

  <li v-if="!token" class="nav-item cursor-pointer">
    <a @click="openSignIn" class="nav-link">
      <div class="text-center d-flex align-items-center justify-content-center">
        <i class="custom-icon profile-icon"></i>
      </div>
      <span class="nav-link-text ms-2">Sign in</span>
    </a>
  </li>

  <li v-if="!token" class="nav-item cursor-pointer">
    <a @click="openSignUp" class="nav-link">
      <div class="icon-sm text-center d-flex align-items-center justify-content-center">
        <i class="custom-icon profile-icon"></i>
      </div>
      <span class="nav-link-text ms-2">Sign Up</span>
    </a>
  </li>
</ul>

  </div> 
 <teleport to="body">
    <signin v-if="isSignIn" @update:emitSignIn="isSignIn = false" />
    <signUp v-if="isSignUp" @update:emitSignUp="closeSignup" />
    <contactUs v-if="isContactUS" @update:emitCloseContactUs="closeContactUs" />
  </teleport>
  </template>
<script setup> 
import { useRoute } from "vue-router"; 
import { ref, computed, onMounted, nextTick } from "vue"; 
import Cookies from 'js-cookie';
import { useStore } from "vuex"; 
import SidenavItem from "./SidenavItem.vue";
import contactUs from "../../views/components/common/contact-us.vue"; 
import signin from "../../views/Signin.vue";
import signUp from "../../views/Signup.vue"; 
import { useMeta } from '../../../src/views/components/composables/useMetaTags';

useMeta(
  `Navigation - Work Break`,
  `Explore the navigation options in the Work Break platform, including Dashboard, Tasks, Archives, Leaves, Support, and Profile.`
);

const store = useStore();
let isSignIn = ref(false);
let isSignUp = ref(false);
let isContactUS = ref(false);

const token = computed(() => {
  return Cookies.get('access_token');
});

const closeSignup = async () => {
  await nextTick();
  isSignUp.value = false;
}
 

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const openSignIn = async () => {
  isSignUp.value = false;  
  isSignIn.value = true;
}

const openContactUs = async () => {  
  isContactUS.value = true;
}

const closeContactUs = async () => {
  await nextTick();
  isContactUS.value = false;
}

const openSignUp = async () => {
  isSignIn.value = false; 
  isSignUp.value = true;
}
const isAdmin = computed(() => store.state.role === "Admin");
onMounted(() => {
  // isAdmin.value = Cookies.get('role') === "Admin" ? true : false;
})
</script>
<style scoped>
.nav-item.disabled {
  opacity: 0.5;
  pointer-events: none; /* Disable clicks */
  cursor: not-allowed; /* Show disabled cursor */
}

.sidenav-item.unclickable {
  pointer-events: none;  
  cursor: not-allowed; /* Show disabled cursor */
}
</style>