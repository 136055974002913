<template>
  <div class="m-2 p-2">
    <h3>About WorkBreak</h3>
    <p><strong>Effective Date:</strong> October 29, 2024</p>
    <p class="card p-3">
      WorkBreak is designed to help individuals and teams manage their work, breaks, and leaves effectively. Our goal is to improve productivity by allowing users to track their tasks and breaks in a simple, user-friendly interface.
    </p>
    <h4>Our Mission</h4>
    <p>
      At WorkBreak, we are dedicated to helping you stay productive and focused. Our mission is to offer tools that help you maintain a healthy work-life balance by giving you control over your breaks and tasks. Whether you're working on a solo project or as part of a team, WorkBreak is here to support your needs.
    </p>
    <h4>Our Services</h4>
    <ul>
      <li><strong>Break Management</strong>: Set and manage your break times to optimize productivity.</li> 
      <li><strong>Task Management</strong>: Track and manage your tasks effectively.</li>
      <li><strong>Leave Management</strong>: Plan and manage your leaves with ease.</li>
    </ul>
    <h4>Why Choose WorkBreak?</h4>
    <p>
      WorkBreak offers a range of features that help boost productivity, including personalized break schedules, task tracking, and easy leave management. Our platform is designed to adapt to your unique needs, helping you stay on top of your tasks while ensuring you take necessary breaks.
    </p>
    <h4>Contact Us</h4>
    <p>If you have any questions or feedback, feel free to reach out:</p>
    <ul>
      <li><strong>Email</strong>: info@work-break.com</li>
      <li><strong>Website</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://work-break.com">https://work-break.com</a></li>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useMeta } from '../composables/useMetaTags';

useMeta(
  'About Us - WorkBreak',
  'Learn more about WorkBreak, our mission, services, and how we help you stay productive while managing your tasks, breaks, and leaves efficiently.'
);

const store = useStore();
store.state.isAbsolute = false;
store.commit("isDashboard", false);
</script>

<style scoped>
a {
  display: contents;
}
</style>
