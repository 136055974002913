<template>
    <div class="card mt-3"> 
        <div class="card-body"> 
            <h2 class="mb-4 text-center">User Role</h2>
            <div class="toggle-container">
    <div
      class="toggle-switch"
      :class="{ active: isAdmin }"
      @click="confirmRoleSwitch"
    >
      <!-- <span class="toggle-label">{{ isAdmin ? 'Admin' : 'Non-Admin' }}</span> -->
      <div class="toggle-slider">{{ isAdmin ? 'Admin' : 'Non-Admin' }}</div>
    </div>
  </div>
  </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Swal from 'sweetalert2';
import apiClient from '../../../api/apiClient';
import Cookies from 'js-cookie'; 
import { useStore } from "vuex";
const store = useStore();
const isAdmin = ref(false); // Assume initial role is non-admin

const confirmRoleSwitch = async () => {
  const newRole = !isAdmin.value ? "Admin" : "Non-Admin";
  const result = await Swal.fire({
    title: 'Are you sure?',
    html: `You are about to switch to <strong>${newRole}</strong> role. 
           This action will update your permissions.`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, switch role',
  });

  if (result.isConfirmed) {
    try {
      const userId = Cookies.get('id');
      const response = await apiClient.put(`/user/update-role/${userId}`, {
        role: newRole
      });
      if (response.data && response.data.status === "success") {
        isAdmin.value = !isAdmin.value;
        Cookies.set('role', newRole, { expires: 7 });
        store.dispatch("broadcastRoleChange", newRole);
        await Swal.fire({
          title: 'Success!',
          text: `Your role has been updated to ${newRole}.`,
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      }
    } catch (error) {
      await Swal.fire({
        title: 'Error!',
        text: 'There was an error updating your role. Please try again.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
    }
  }
};


onMounted( async() => {
  isAdmin.value = Cookies.get('role') === "Admin" ? true : false;
});
</script>
<style scoped>
/* Container for the toggle */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* Toggle switch */
.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: #ddd;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active state */
.toggle-switch.active {
  background-color: #007bff;
}

/* Toggle label */
.toggle-label {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  z-index: 2;
  pointer-events: none;
}

/* Slider (draggable effect) */
.toggle-slider {
  position: absolute;
  width: 49%;
  height: 30px;
  background-color: #fff;
  border-radius: 15px;
  transition: transform 0.3s ease;
  z-index: 1;
  transform: translateX(0);
  text-align: center;
  padding-top: 3px;
}

/* Slider active state */
.toggle-switch.active .toggle-slider {
  transform: translateX(100%);
}
</style>