<template>
  <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header color-black">
          <h5 class="modal-title"> 
            Viewing <strong>{{ details?.name }}</strong> Leaves
          </h5>
          <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close">
            <i class="custom-icon close-icon icon-lg"></i>
          </button>
        </div>
        <div class="modal-body"> 
    <loading v-if="isLoading"></loading>
    <div class="table-responsive pt-2" v-if="!isLoading && leavesList?.length > 0">
      <table class="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
              Reason
            </th>
            <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">
              Day(s)
            </th>
            <th class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2">
              From Date
            </th>
            <th class="text-uppercase text-center text-secondary text-sm font-weight-bolder opacity-7 ps-2">
              To Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, index) in leavesList"
            :key="index"
            :class="{ 'disabled-color': isEdit && editedIndex === index }"
          >
            <td class="text-truncate">
              <div class="d-flex px-2">
                <div class="my-auto">
                  <h6 class="mb-0 text-muted handle-large-text" :title="value.reason">
                    {{ value.reason }}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-muted mb-0 handle-large-text" :title="value.days">
                {{ value.days }}
              </p>
            </td>
            <td class="text-center">
              <span class="text-muted align-middle">
                {{ convertDate(value.fromDate) }}
              </span>
            </td>
            <td class="text-center">
              <span class="text-muted align-middle">
                {{ value.toDate ? convertDate(value.toDate) : value.toDate }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="leavesList?.length === 0" class="alert alert-warning d-flex align-items-center" role="alert">
          <span>This user has not avail any <strong>Leave</strong> yet.</span>
        </div> 
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import { shallowRef, ref, onMounted } from 'vue';
import apiClient from '../../../api/apiClient';
import { useMeta } from '../composables/useMetaTags'; 
import { convertDate } from "../../utilities/transforms";
import { Modal } from "bootstrap"; 

useMeta(
  'Administrators Dashboard | Break Management',
  'WorkBreak empowers administrators to view and manage user tasks effectively. Monitor task schedules, review details, and ensure optimal productivity with streamlined management tools.'
);

const { details } = defineProps({
  details: { type: Object, required: true }
});

const modalRef = shallowRef(null);
let modal = null;
 const leavesList = ref([]);
const isLoading = ref(false);

const getLeaves = async() => {
  try {
    isLoading.value = true;
     const leaves = await apiClient.get('leaves/get', {
        params: {
          userId: details.userId
        }
      });
      if(leaves?.data){
        leavesList.value = leaves.data;
      } 
    } catch (error) {
      console.error('Error:', error); 
    }
    finally {
      isLoading.value = false;
    }
  }

 onMounted( async() => {
   modal = new Modal(modalRef.value);
   modal.show();
   await getLeaves();
 }); 

const emit = defineEmits(["update:closeTasks"]);
function closeDialog() {
  emit('update:closeTasks', false);
  modal.hide();
}
</script>

 
